import { Injectable } from "@angular/core";
import * as sessionlistActions from './sessionlist.actions';
import * as sessionlistReducers from './sessionlist.reducers';
import { Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { take , map} from "rxjs/operators";
import { Observable } from "rxjs";
import { SessionModel } from "@smarttask-common/src/lib/models/Internal/SessionModel";

@Injectable({
    providedIn: 'root'
})
export class SessionlistStoreService {
    
    constructor(
        private sessionlistStore: Store<sessionlistReducers.SessionlistState>
    ){
        // this.test();
    }

    // test = () =>{
    //     this.sessionlistStore.select(sessionlistReducers.getSelectedSession).subscribe(x=> {
    //         console.log(x);
    //     })
    // }

    watchlist = ():Observable<SessionModel[]> =>{
        return this.sessionlistStore.select(sessionlistReducers.selectAll);
    }

    watchSelectedSessionId = (): Observable<string> =>{
        return this.sessionlistStore.select(sessionlistReducers.getSelectedSessionId);
    }
    watchSelectedSession = (): Observable<SessionModel>=>{
        return this.sessionlistStore.select(sessionlistReducers.getSelectedSession);
    }

    getlist = () =>{
        var list = new Array<SessionModel>();
        this.sessionlistStore.select(sessionlistReducers.selectAll).pipe(take(1)).subscribe(sessions =>{
            list = sessions;
        });
        return list;
    }
    getBySessionId = (session_id: string): SessionModel | undefined =>{
        var sessionlist = new Array<SessionModel>();
        this.sessionlistStore.select(sessionlistReducers.selectAll).pipe(take(1)).subscribe(x=> {sessionlist = x});
        return sessionlist.find(x=> x.session_id == session_id);
    }
    getSelectedSession = (): SessionModel=>{
        var session;
        this.sessionlistStore.select(sessionlistReducers.getSelectedSession).pipe(take(1)).subscribe(x=> session = x);
        return session;
    }
    getSelectedSessionId = (): string =>{
        var selected_session_id: string;
        this.sessionlistStore.select(sessionlistReducers.getSelectedSessionId).pipe(take(1)).subscribe(x=> selected_session_id = x);
        return selected_session_id;
    }

    addOne = (session: SessionModel) =>{
        this.sessionlistStore.dispatch(new sessionlistActions.AddOne(session));
    }

    addMany = (sessions: Array<SessionModel>) =>{
        this.sessionlistStore.dispatch(new sessionlistActions.AddMany(sessions));
    }

    upsertOne = (session: SessionModel) =>{
        this.sessionlistStore.dispatch(new sessionlistActions.UpsertOne(session));
    }

    upsertMany = (sessions: Array<SessionModel>) =>{
        this.sessionlistStore.dispatch(new sessionlistActions.UpsertMany(sessions));
    }

    updateSelectedSessionId = (session_id: string) =>{
        this.sessionlistStore.dispatch(new sessionlistActions.UpdateSelectedSessionId(session_id));
    }

    updateOne = (id: string,changes: Partial<SessionModel>) =>{
        var update = <Update<SessionModel>>{
            id: id,
            changes: changes
        };
        this.sessionlistStore.dispatch(new sessionlistActions.UpdateOne(update));
    }

    updateMany = (updates: Array<Update<SessionModel>>) =>{
        this.sessionlistStore.dispatch(new sessionlistActions.UpdateMany(updates));
    }

    deleteOne = (session_id: string) =>{
        this.sessionlistStore.dispatch(new sessionlistActions.DeleteOne(session_id));
    }

    deleteMany= (session_ids: string[]) =>{
        this.sessionlistStore.dispatch(new sessionlistActions.DeleteMany(session_ids));
    }

    deleteAll = () =>{
        var list = this.getlist();
        this.sessionlistStore.dispatch(new sessionlistActions.DeleteMany(list.map(x=> x.session_id)));
    }
}