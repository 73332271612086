import { OrganizationlistStoreService } from '@smarttask-app/src/app/services/Organization/store/organizationlistStore.service';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Globals } from "@smarttask-common/src/lib/services/globals";
import { SessionModel } from '@smarttask-common/src/lib/models/Internal/SessionModel';
import { DateService } from '@smarttask-common/src/lib/services/General/date.service';
import { SessionlistStoreService } from './store/sessionlistStore.service';
import { DateTime } from 'luxon';
import { OrganizationHubService } from '../SignalRHubs/organizationHub.service';
import { SharedService } from '../AngularSpecific/shared.service';
import { SharedEventNameEnum } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventNameEnum';
import { SharedEventModel } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventModel';

//Added to initializationService so this service gets initiated immediately
@Injectable({
    providedIn: 'root'
})
export class SessionService {
    constructor(
        private http: HttpClient,
        private globals: Globals,
        private dateService: DateService,
        private sharedService: SharedService,
        private organizationlistStoreService: OrganizationlistStoreService,
        private sessionlistStoreService: SessionlistStoreService,
        private organizationHubService: OrganizationHubService
    ){
        this.startLiveUserPing();
        this.watchSelectedOrganization();
        this.listenForLiveUserPing();
    }

    isUserLive = (user_id: number) =>{
        let rel_sessions = this.sessionlistStoreService.getlist().filter(x=> x.user_id == user_id);
        //Filter out sessions that are older than 4 minutes, user is no longer live
        let session = rel_sessions.find(x => x.started_at > DateTime.now().minus({ minutes: 4 }).toJSDate());
        if(session){
            return true;
        }
        else{
            return false;
        }
    }

    getLiveUsers = () =>{
        let organization_id = this.organizationlistStoreService.getSelectedOrganizationId();
        return this.http.get(`${this.globals.api_base_url}${this.globals.api_version}/session/live-users/${organization_id}`).toPromise().then((sessions: SessionModel[])=>{
            sessions.forEach(session => {
                session.started_at = this.dateService.serverDateTimeToLocal(session.started_at);
                session.ended_at = this.dateService.serverDateTimeToLocal(session.ended_at);
            });
            this.sessionlistStoreService.upsertMany(sessions);
            return sessions;
        });
    }

    startLiveUserPing = () =>{
        setInterval(()=>{
            let organization_id = this.organizationlistStoreService.getSelectedOrganizationId();
            if(organization_id != undefined && this.globals.auth_data != undefined && this.globals.auth_data.user_id != undefined){
                this.organizationHubService.organizationUserPing(organization_id,this.globals.auth_data.user_id);
            }
        },240000)
    }

    watchSelectedOrganization = () =>{
        this.organizationlistStoreService.watchSelectedOrganizationId().subscribe(organization_id =>{
            this.sessionlistStoreService.deleteAll();
            if(organization_id != undefined){
                this.getLiveUsers();
            }
        })
    }

    listenForLiveUserPing = () =>{
        this.sharedService.on(SharedEventNameEnum.organization_user_ping,(e: SharedEventModel)=>{
            var session_id = e.obj.session_id;
            var user_id = e.obj.user_id;
            var session = this.sessionlistStoreService.getBySessionId(session_id);
            if(session){
                var update: Partial<SessionModel> = {
                    user_id: user_id,
                    started_at: new Date()
                }
                this.sessionlistStoreService.updateOne(session_id,update);
            }
            else{
                var new_session: SessionModel ={
                    session_id: session_id,
                    user_id: user_id,
                    started_at: new Date(),
                    ended_at: undefined
                } ;
                this.sessionlistStoreService.upsertOne(new_session);
            }
        })
    }
}