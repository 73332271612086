import * as actions from './sessionlist.actions';
import { EntityState, createEntityAdapter, Update } from '@ngrx/entity';
import { createFeatureSelector, createSelector, Store} from '@ngrx/store';
import { SessionModel } from '@smarttask-common/src/lib/models/Internal/SessionModel';


export const sessionAdapter = createEntityAdapter<SessionModel>({
  selectId: x => x.session_id
});
export interface SessionlistState extends EntityState<SessionModel>{
  selected_session_id: string
};

export const initialState: SessionlistState = sessionAdapter.getInitialState({
  selected_session_id: undefined,
}); 


export function SessionlistReducers(
  state: SessionlistState = initialState,
  action: actions.SessionlistActions
){
  switch(action.type){
    case actions.SessionlistActionTypes.UPDATE_SELECTED_ID:
      state = {...state, selected_session_id: action.selected_session_id};
      //console.log(state);
      return state;

    case actions.SessionlistActionTypes.ADD_SESSION: 
      return sessionAdapter.addOne(action.session, {...state,selected_session_id: state.selected_session_id});

    case actions.SessionlistActionTypes.ADD_SESSIONS:
      return sessionAdapter.addMany(action.sessions,{...state,selected_session_id: state.selected_session_id});

    case actions.SessionlistActionTypes.UPSERT_SESSION:
      return sessionAdapter.upsertOne(action.session,{...state,selected_session_id: state.selected_session_id});
      
    case actions.SessionlistActionTypes.UPSERT_SESSIONS:
      return sessionAdapter.upsertMany(action.sessions,{...state,selected_session_id: state.selected_session_id});
  
    case actions.SessionlistActionTypes.UPDATE_SESSION:
      return sessionAdapter.updateOne(action.session,{...state,selected_session_id: state.selected_session_id});

    case actions.SessionlistActionTypes.UPDATE_SESSIONS:
      return sessionAdapter.updateMany(action.sessions,{...state,selected_session_id: state.selected_session_id});

    case actions.SessionlistActionTypes.DELETE_SESSION:
      // if(state.selected_session_id == action.id){
      //   state.selected_session_id = undefined;
      // }
      return sessionAdapter.removeOne(action.id,{...state,selected_session_id: state.selected_session_id});

    case actions.SessionlistActionTypes.DELETE_SESSIONS:
      // if(action.ids.indexOf(state.selected_session_id) > -1){
      //   state.selected_session_id = undefined;
      // }
      return sessionAdapter.removeMany(action.ids,{...state,selected_session_id: state.selected_session_id});

    case actions.SessionlistActionTypes.DELETE_ALL_SESSIONS:
      return sessionAdapter.removeMany(action.ids,{...state,selected_session_id: state.selected_session_id});

    case actions.SessionlistActionTypes.DELETE_ALL:
      state.selected_session_id = undefined;
      return sessionAdapter.removeAll(state);

    default:
      return state;
  }
}

export const getSessionlistState = createFeatureSelector<SessionlistState>('sessionlist');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = sessionAdapter.getSelectors(getSessionlistState);

const getSelectedSessionIdState = (state: SessionlistState) => { 
  return state.selected_session_id
};
export const getSelectedSessionId = createSelector(getSessionlistState, getSelectedSessionIdState);

export const getSelectedSession = createSelector(getSessionlistState, (state: SessionlistState)=>{
  if(state.selected_session_id == undefined){
    return undefined;
  }
  return state.entities[state.selected_session_id];
})

