import {Action} from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { SessionModel } from '@smarttask-common/src/lib/models/Internal/SessionModel';

export enum SessionlistActionTypes {
  ADD_SESSION = '[Session] Create Session',
  UPSERT_SESSION = '[Session] Upsert Session',
  ADD_SESSIONS = '[Session] Add Sessions',
  UPSERT_SESSIONS = '[Session] Upsert Sessions',
  UPDATE_SESSION = '[Session] Update Session',
  UPDATE_SESSIONS = '[Session] Update Sessions',
  DELETE_SESSION = '[Session] Delete Session',
  DELETE_SESSIONS = '[Session] Delete Sessions',
  DELETE_ALL_SESSIONS = '[Session] Delete All Sessions',
  DELETE_ALL = '[Session] Delete All',

  UPDATE_SELECTED_ID = '[Session] Update SelectedId'
}

export class UpdateSelectedSessionId implements Action{
  readonly type = SessionlistActionTypes.UPDATE_SELECTED_ID;
  constructor(public selected_session_id: string){}
}

export class AddOne implements Action{
  readonly type = SessionlistActionTypes.ADD_SESSION;
  constructor(public session: SessionModel){

  }
}

export class AddMany implements Action{
  readonly type = SessionlistActionTypes.ADD_SESSIONS;
  constructor(public sessions: Array<SessionModel>){

  }
}

export class UpsertOne implements Action {
  readonly type = SessionlistActionTypes.UPSERT_SESSION;

  constructor(
    public session: SessionModel) {}
}

export class UpsertMany implements Action {
  readonly type = SessionlistActionTypes.UPSERT_SESSIONS;
  constructor(public sessions: Array<SessionModel> ) {}
}

export class UpdateOne implements Action{
  readonly type = SessionlistActionTypes.UPDATE_SESSION;
  constructor(public session: Update<SessionModel>){
    
  }
}

export class UpdateMany implements Action{
  readonly type = SessionlistActionTypes.UPDATE_SESSIONS;
  constructor(public sessions: Array<Update<SessionModel>>){
    
  }
}

export class DeleteOne implements Action{
  readonly type = SessionlistActionTypes.DELETE_SESSION;
  constructor(
    public id: string
  ){}
}

export class DeleteMany implements Action{
  readonly type = SessionlistActionTypes.DELETE_SESSIONS;
  constructor(
    public ids: Array<string>
  ){}
}

export class DeleteAll implements Action{
  readonly type = SessionlistActionTypes.DELETE_ALL_SESSIONS;
  constructor(public ids: Array<string>){}
}

export class DeleteState implements Action{
  readonly type = SessionlistActionTypes.DELETE_ALL;
  constructor(
  ){}
}

export type SessionlistActions = 
  AddOne 
  | AddMany 
  | UpsertOne 
  | UpsertMany 
  | DeleteOne 
  | DeleteMany 
  | DeleteAll
  | DeleteState
  | UpdateOne
  | UpdateMany
  | UpdateSelectedSessionId;